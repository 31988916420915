//Stlyes
import '../scss/styles.scss';
import { Slider } from './components/slider';
document.addEventListener("DOMContentLoaded", function () {
    var _a;
    //Sliders
    var ourServicesSlider = new Slider('.our-services-slider', 1, false, false, [
        {
            breakpoint: 992,
            settings: "unslick"
        }
    ], true);
    ourServicesSlider.setNextArrow('.our-services .next');
    ourServicesSlider.setPrevArrow('.our-services .prev');
    var ourPeopleSlider = new Slider('.our-people-slider', 2, false, false);
    ourPeopleSlider.setNextArrow('.our-people .next');
    ourPeopleSlider.setPrevArrow('.our-people .prev');
    var howItWorksSlider = new Slider('.how-it-works-slider', 3, false, false, [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1
            }
        }
    ]);
    howItWorksSlider.setNextArrow('.next');
    howItWorksSlider.setPrevArrow('.prev');
    //Mobile Menu
    var headerHeight = (_a = document.querySelector('header')) === null || _a === void 0 ? void 0 : _a.offsetHeight;
    var mobileMenu = document.querySelector('.mobile-menu');
    if (mobileMenu && headerHeight) {
        mobileMenu.style.top = headerHeight + "px";
    }
    var menuButton = document.querySelector('.expand-mobile-nav');
    menuButton === null || menuButton === void 0 ? void 0 : menuButton.addEventListener('click', function () {
        var _a;
        (_a = document.querySelector('body')) === null || _a === void 0 ? void 0 : _a.classList.toggle('mobile-expand');
    });
});
