import $ from 'jquery';
import 'slick-carousel';
var Slider = /** @class */ (function () {
    function Slider(selector, slidesToShow, infinite, arrows, breakpoints, mobileFirst) {
        if (slidesToShow === void 0) { slidesToShow = 3; }
        if (infinite === void 0) { infinite = true; }
        if (arrows === void 0) { arrows = true; }
        if (breakpoints === void 0) { breakpoints = []; }
        if (mobileFirst === void 0) { mobileFirst = false; }
        this.selector = selector;
        this.slidesToShow = slidesToShow;
        this.infinite = infinite;
        this.arrows = arrows;
        this.mobileFirst = mobileFirst;
        this.breakpoints = [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                }
            }
        ];
        if (breakpoints.length) {
            this.breakpoints = breakpoints;
        }
        this.initSlider();
    }
    Slider.prototype.initSlider = function () {
        var _this = this;
        var sliderElement = $(this.selector);
        if (!sliderElement.length) {
            return null;
        }
        sliderElement.slick({
            infinite: this.infinite,
            slidesToShow: this.slidesToShow,
            slidesToScroll: 1,
            arrows: this.arrows,
            draggable: false,
            responsive: this.breakpoints,
            mobileFirst: this.mobileFirst
        });
        this.slick = sliderElement.slick('getSlick');
        sliderElement.on('beforeChange', function (e, slick, before, after) {
            _this.adjustArrowClasses(after);
        });
        setTimeout(function () {
            _this.adjustArrowClasses();
        }, 0);
    };
    Slider.prototype.adjustArrowClasses = function (newIndex) {
        if (newIndex === void 0) { newIndex = 0; }
        if (this.prevArrowSelector) {
            var prevArrow = $(this.prevArrowSelector);
            if (newIndex <= 0) {
                prevArrow.addClass('disabled');
            }
            else {
                prevArrow.removeClass('disabled');
            }
        }
        if (this.nextArrowSelector && this.slick.slideCount) {
            var nextArrow = $(this.nextArrowSelector);
            if (newIndex >= this.slick.slideCount - this.slick.options.slidesToShow) {
                nextArrow.addClass('disabled');
            }
            else {
                nextArrow.removeClass('disabled');
            }
        }
    };
    Slider.prototype.setPrevArrow = function (selector) {
        var _this = this;
        this.prevArrowSelector = selector;
        $(selector).on("click", function () {
            $(_this.selector).slick('slickPrev');
        });
    };
    Slider.prototype.setNextArrow = function (selector) {
        var _this = this;
        this.nextArrowSelector = selector;
        $(selector).on("click", function () {
            $(_this.selector).slick('slickNext');
        });
    };
    return Slider;
}());
export { Slider };
